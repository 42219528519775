import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotAvailable() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <>
            <section id="content-page-851">
                <div className="cs-container">
                    <div className="cs-content">
                        <h3>Not Available</h3>
                        <p>
                            The resource associated with this case is not yet available.
                        </p>
                        <p>Please try again later.</p>
                        <button onClick={goBack} className="cs-button-solid">Go Back</button>
                        {/* More case details here */}

                    </div>
                    <div className="cs-image-group">
                        <picture className="cs-picture">
                            <source media="(max-width: 600px)" srcSet="https://images.unsplash.com/photo-1506704810770-7e9bbab1094b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" />
                            <source media="(min-width: 601px)" srcSet="https://images.unsplash.com/photo-1506704810770-7e9bbab1094b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWgelbHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" />
                            <img decoding="async" src="https://images.unsplash.com/photo-1506704810770-7e9bbab1094b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWgelbHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="Please Wait" width="542" height="520" aria-hidden="true" />
                        </picture>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NotAvailable;
