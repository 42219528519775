import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';

function Services() {
    return (
        <>
            <section id="services-17">
                <div className="cs-container">
                    <div className="cs-content">
                        <span className="cs-topper">Services</span>
                        <h2 className="cs-title">Delivering Excellence in Immigration Law</h2>
                        <p className="cs-text">
                            We specialize in a range of immigration legal services. Our dedicated team is ready to guide you through your unique immigration case.
                        </p>
                    </div>
                    <ul className="cs-card-group">
                        {/* Repeating content starts */}
                        {[
                            {
                                service: 'Visa Applications',
                                description: 'Expert guidance through the complex process of visa applications.'
                            },
                            {
                                service: 'Citizenship Applications',
                                description: 'Assistance in filing for and obtaining citizenship.'
                            },
                            {
                                service: 'Deportation Defense',
                                description: 'Strong legal representation to defend against deportation.'
                            },
                            {
                                service: 'Asylum Assistance',
                                description: 'Comprehensive help for asylum seekers.'
                            },
                            {
                                service: 'Business Immigration',
                                description: 'Assistance with immigration processes related to business needs.' // Adjust this description as needed
                            },
                            {
                                service: 'Appeals',
                                description: 'Guidance and representation in the appeals process.'
                            }
                        ].map(item => (
                            <li className="cs-item">
                                <picture className="cs-picture">
                                    <img className="cs-icon" src="https://cdn-icons-png.flaticon.com/512/942/942803.png" loading="lazy" decoding="async" alt="icon" width="33" height="38" aria-hidden="true" />
                                </picture>
                                <div className="cs-text-group">
                                    <h3 className="cs-h3">{item.service}</h3>
                                    <p className="cs-item-text">{item.description}</p>
                                </div>
                            </li>
                        ))}
                        {/* Repeating content ends */}
                    </ul>
                    <Link to="/contact" className="cs-button-solid">Book A Consultation</Link>
                </div>
            </section>
            <section id="reviews-399">
                <div className="cs-container">
                    <div className="cs-content">
                        <span className="cs-topper">Testimonials</span>
                        <h2 className="cs-title">Trusted by Thousands of Individuals & Families</h2>
                        <p className="cs-text">
                            At our law firm, we are committed to solving complex immigration issues and helping you achieve your goals. We have supported countless individuals and families through their immigration journey.
                        </p>
                    </div>
                    <ul className="cs-card-group">
                        {/* Repeating content starts */}
                        {[{
                            review: "Ex. The team guided me seamlessly through my visa application process. I am so grateful for their help!",
                            name: "Maria",
                            job: "Software Developer",
                            profile: "https://csimg.nyc3.digitaloceanspaces.com/Reviews/profile2.jpg"
                        }, {
                            review: "Ex. Their assistance with my application for DACA was invaluable. I can't imagine going through this without them.",
                            name: "David",
                            job: "Teacher",
                            profile: "https://csimg.nyc3.digitaloceanspaces.com/Reviews/profile3.png"
                        }, {
                            review: "Ex. With their deportation defense, my family was able to stay together in the U.S. They were a beacon of hope in our darkest time.",
                            name: "Gloria",
                            job: "Small Business Owner",
                            profile: "https://csimg.nyc3.digitaloceanspaces.com/Reviews/profile2.png"
                        }].map(item => (
                            <li className="cs-item">
                                <img className="cs-quote" aria-hidden="true" src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/quotes-white.svg" decoding="async" alt="quote icon" width="40" height="33" />
                                <img className="cs-quote-white" aria-hidden="true" src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/quotes-white.svg" decoding="async" alt="quote icon" width="60" height="55" />
                                <p className="cs-review">
                                    {item.review}
                                </p>
                                <div className="cs-flex-group">
                                    <picture className="cs-picture">
                                        <img className="cs-profile" src={item.profile} decoding="async" alt="profile" width="50" height="50" aria-hidden="true" />
                                    </picture>
                                    <span className="cs-name">
                                        {item.name}
                                        <span className="cs-job">{item.job}</span>
                                    </span>
                                </div>
                            </li>
                        ))}
                        {/* Repeating content ends */}
                    </ul>
                </div>
            </section>

        </>
    )
}

export default Services;
