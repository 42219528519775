import React from 'react';
import { useParams } from 'react-router-dom';
import DecisionNotFound from '../NotAvailable/NotAvailable.js'
import './SingleCaseView.css';

function SingleCaseView({ casesData }) {
    const { id } = useParams();

    // Find the case that matches the id in the URL
    const caseDetail = casesData.find(caseItem => caseItem.id === Number(id));

    // If there's no matching case, return null or some 404 component
    if (!caseDetail) return <DecisionNotFound />;
    // or return <NotFound />;


    return (
        <>
            <section id="content-page-851">
                <div className="cs-container">
                    <div className="cs-content">
                        <h1 className="cs-title">{caseDetail.title}</h1>
                        <h3>{caseDetail.date}</h3>
                        <p>
                            {caseDetail.description}
                        </p>
                        <div className='cs-button-container'>
                            <a href={caseDetail.linkToArg} className="cs-button-solid">View Full Decision</a>
                            <a href={caseDetail.linkToAudio} className="cs-button-solid argument">Listen To Argument</a>
                        </div>
                        {/* More case details here */}
                        <p className="disclaimer">
                            {caseDetail.note}
                        </p>
                    </div>
                    <div className="cs-image-group">
                        <picture className="cs-picture">
                            <source media="(max-width: 600px)" srcSet={caseDetail.imgSrc} />
                            <source media="(min-width: 601px)" srcSet={caseDetail.imgSrc} />
                            <img decoding="async" src={caseDetail.imgSrc} alt={caseDetail.altText} width="542" height="520" aria-hidden="true" />
                        </picture>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SingleCaseView;
