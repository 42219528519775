import React from 'react';
import { Link } from 'react-router-dom';

import './Hero.css';
// import Ross1 from '../../assets/images/Ross1.webp';



function Hero() {

    const Ross1 = 'https://ik.imagekit.io/s3i7pk3bg/Ross1.webp?updatedAt=1688668574630';

    return (
        <>
            <section id="hero-288">
                <div className="cs-container">
                    <div className="cs-content">
                        <h1 className="cs-title">Your Ally in Immigration Law</h1>
                        <p className="cs-text">
                            Emigrating to a new country can be an overwhelming process. Our dedicated team is here to guide you through every step of the journey, ensuring a smooth transition and successful result.
                        </p>
                        <Link to="/contact" className="cs-button-solid">Make An Appointment</Link>
                        <ul className="cs-stats-group">
                            <li className="cs-item">
                                <span className="cs-number">7</span>
                                <span className="cs-desc">U.S. Circuit Court Oral Arguments</span>
                            </li>
                            <li className="cs-item">
                                <span className="cs-number">5x</span>
                                <span className="cs-desc">Conference & CLE Speaker</span>
                            </li>
                            <li className="cs-item">
                                <span className="cs-number">3</span>
                                <span className="cs-desc">Super Lawyers Rising Star Awards</span>
                            </li>
                        </ul>
                    </div>
                    <picture className="cs-picture">
                        <source media="(max-width: 600px)" srcSet={Ross1} />
                        <source media="(min-width: 601px)" srcSet={Ross1} />
                        <source media="(min-width: 1024px)" srcSet={Ross1} />
                        <img aria-hidden="true" decoding="async" src={Ross1} alt="Photo of Ross Miller" width="630" height="814" />
                    </picture>
                    <picture className="cs-waves">
                        <source media="(min-width: 1024px)" srcSet="https://csimg.nyc3.digitaloceanspaces.com/Hero/waves.svg" />
                        <source media="(min-width: 601px)" srcSet="https://csimg.nyc3.digitaloceanspaces.com/Hero/waves-t.svg" />
                        <source media="(max-width: 600px)" srcSet="https://csimg.nyc3.digitaloceanspaces.com/Hero/waves-m.svg" />
                        <img decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Hero/waves.svg" alt="green waves" width="645" height="1080" aria-hidden="true" />
                    </picture>
                </div>
            </section>

            <section id="services-345">
                <div className="cs-container">
                    <div className="cs-content">
                        <span className="cs-topper">Experienced Legal Support</span>
                        <h2 className="cs-title">Your Pathway to Immigration Services</h2>
                        <p className="cs-text">
                            At our Law Firm, we offer you comprehensive immigration solutions so you can focus on your new journey. We guide individuals and businesses through the complex immigration process.
                        </p>
                    </div>
                    <ul className="cs-card-group">
                        <li className="cs-item">
                            <div className="cs-link">
                                <picture className="cs-picture">
                                    <img className="cs-icon" src="https://cdn-icons-png.flaticon.com/512/2648/2648510.png" loading="lazy" decoding="async" alt="icon" width="32" height="32" aria-hidden="true" />
                                </picture>
                                <h3 className="cs-h3">Visa Applications</h3>
                                <p className="cs-item-text">
                                    We’ve assisted thousands in applying for and obtaining various types of visas.
                                </p>
                            </div>
                        </li>
                        <li className="cs-item">
                            <div className="cs-link">
                                <picture className="cs-picture">
                                    <img className="cs-icon" src="https://cdn-icons-png.flaticon.com/512/2245/2245059.png" loading="lazy" decoding="async" alt="icon" width="32" height="32" aria-hidden="true" />
                                </picture>
                                <h3 className="cs-h3">Citizenship Applications</h3>
                                <p className="cs-item-text">
                                    We help streamline the process of applying for citizenship, making it less stressful and more successful.
                                </p>
                            </div>
                        </li>
                        <li className="cs-item">
                            <div className="cs-link">
                                <picture className="cs-picture">
                                    <img className="cs-icon" src="https://cdn-icons-png.flaticon.com/512/7238/7238301.png" loading="lazy" decoding="async" alt="icon" width="32" height="32" aria-hidden="true" />
                                </picture>
                                <h3 className="cs-h3">Deportation Defense</h3>
                                <p className="cs-item-text">
                                    Our experienced attorneys provide vigorous defense against deportation and removal proceedings.
                                </p>
                            </div>
                        </li>
                        <li className="cs-item">
                            <div className="cs-link">
                                <picture className="cs-picture">
                                    <img className="cs-icon" src="https://cdn-icons-png.flaticon.com/512/8709/8709703.png" loading="lazy" decoding="async" alt="icon" width="32" height="32" aria-hidden="true" />
                                </picture>
                                <h3 className="cs-h3">Asylum Assistance</h3>
                                <p className="cs-item-text">
                                    We've guided countless individuals through the asylum application process, providing the support they need.
                                </p>
                            </div>
                        </li>
                    </ul>
                    <Link to="/services" className="cs-button-solid">View Services</Link>
                </div>
            </section>
            <section id="why-choose-37">
                <div className="cs-container">
                    <picture className="cs-picture">
                        <source media="(max-width: 600px)" srcSet="https://images.unsplash.com/photo-1525799165-f95e2ccbb152?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" />
                        <source media="(min-width: 601px)" srcSet="https://images.unsplash.com/photo-1525799165-f95e2ccbb152?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" />
                        <img aria-hidden="true" loading="lazy" decoding="async" src="https://images.unsplash.com/photo-1525799165-f95e2ccbb152?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="citizenship ceremony" width="858" height="781" />
                    </picture>
                    <div className="cs-content">
                        <span className="cs-topper">Why Partner With Us</span>
                        <h2 className="cs-title">The Compass for Your Immigration Journey</h2>
                        <p className="cs-text">
                            Our expertise extends beyond knowledge and experience. Our passion is to guide you as an ally, standing by your side throughout your journey in immigration processes. You can count on us for personalized, compassionate, and effective legal support.
                        </p>
                        <ul className="cs-ul">
                            <li className="cs-li">
                                <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Why-Choose/yellow-check.svg" alt="checkmark" width="27" height="27" />
                                Customized Care
                            </li>
                            <li className="cs-li">
                                <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Why-Choose/yellow-check.svg" alt="checkmark" width="27" height="27" />
                                Attention To Detail
                            </li>
                            <li className="cs-li">
                                <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Why-Choose/yellow-check.svg" alt="checkmark" width="27" height="27" />
                                Record of Success
                            </li>
                            <li className="cs-li">
                                <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Why-Choose/yellow-check.svg" alt="checkmark" width="27" height="27" />
                                Expert Guidance
                            </li>
                        </ul>
                        <Link to="/contact" className="cs-button-solid">Book A Consultation</Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
