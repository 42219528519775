import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import blackLogo from '../../assets/images/ross.miller.esq.logo.black.png';

function Footer() {
    const [activeRoute, setActiveRoute] = useState('');
    const location = useLocation();

    useEffect(() => {
        const currentRoute = location.pathname.split('/')[2] || 'home';
        setActiveRoute(currentRoute);
    }, [location]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <footer id="cs-footer-269">
                <div className="cs-container">
                    <div className="cs-logo-group">
                        <Link to="/" aria-label="go back to home" className="cs-logo">
                            <img
                                className="cs-logo-img light"
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src={blackLogo}

                                alt="logo"
                                width="240"
                                height="32" />
                        </Link>
                        <p className="cs-text">
                            Ross Miller, a trusted attorney at Gonzalez Olivieri LLC, along with his expert team, is committed to being the reliable guide you need in your immigration journey.
                        </p>
                    </div>
                    <ul className="cs-nav">
                        <li className="cs-nav-li">
                            <span className="cs-header">Sitemap</span>
                        </li>
                        <li className="cs-nav-li">
                            <Link to="/" className={`cs-nav-link ${activeRoute === 'home' ? 'cs-active' : ''}`}>Home</Link>
                        </li>
                        <li className="cs-nav-li">
                            <Link to="/about" className={`cs-nav-link ${activeRoute === 'about' ? 'cs-active' : ''}`}>About</Link>
                        </li>
                        <li className="cs-nav-li">
                            <Link to="/services" className={`cs-nav-link ${activeRoute === 'services' ? 'cs-active' : ''}`}>Services</Link>
                        </li>
                        <li className="cs-nav-li">
                            <Link to="/cases" className={`cs-nav-link ${activeRoute === 'cases' ? 'cs-active' : ''}`}>Cases</Link>
                        </li>
                        <li className="cs-nav-li">
                            <Link to="/contact" className={`cs-nav-link ${activeRoute === 'contact' ? 'cs-active' : ''}`}>Get In Touch</Link>
                        </li>
                        <li className="cs-nav-li">
                            <a href="https://www.gonzalezolivierillc.com/" className="cs-nav-link">Gonzalez Olivieri, LLC</a>
                        </li>
                    </ul>
                    <ul className="cs-contact">
                        <li className="cs-nav-li">
                            <span className="cs-header">Contact</span>
                        </li>
                        <li className="cs-contact-li">
                            <Link className="cs-contact-link" href="tel:123-456-7890">(713) 481-3040</Link>
                        </li>
                        <li className="cs-contact-li">
                            <Link className="cs-contact-link" href="mailto:rmiller@gonzalezolivierillc.com">rmiller@gonzalezolivierillc.com</Link>
                        </li>
                        <li className='cs-contact-li'>
                            <a className="cs-social-link" aria-label="visit LinkedIn profile" href="https://www.linkedin.com/in/ross-miller-esq/">
                                <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://cdn-icons-png.flaticon.com/512/145/145807.png" alt="google" width="10rem" height="10rem" />
                            </a>
                        </li>

                        {/* <li className="cs-contact-li cs-social-group">
                            <div className="cs-social">
                                <Link className="cs-social-link" aria-label="visit google profile" href="#link">
                                    <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Social/google.svg" alt="google" width="11" height="11" />
                                </Link>
                                <Link className="cs-social-link" aria-label="visit facebook profile" href="#link">
                                    <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Social/Facebook.svg" alt="facebook" width="6" height="11" />
                                </Link>
                                <Link className="cs-social-link" aria-label="visit instagram profile" href="#link">
                                    <img className="cs-social-img" aria-hidden="true" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Social/instagram.svg" alt="instagram" width="11" height="11" />
                                </Link>
                            </div>
                        </li> */}
                    </ul>
                </div>
                <div className="cs-creator">
                    Web Engineering by Fidel Deaquino
                </div>

            </footer>

        </>
    )
}

export default Footer;
