import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.js';
import Hero from './components/Hero/Hero.js';
import About from './components/About/About.js';
import Services from './components/Services/Services.js';
import Cases from './components/Cases/Cases.js';
import Contact from './components/Contact/Contact.js';
import SingleCaseView from './components/SingleCaseView/SingleCaseView.js';
import Footer from './components/Footer/Footer.js';
import Modal from './components/Modal/Modal.js';
import NotAvailable from './components/NotAvailable/NotAvailable.js';


function App() {
  // Add a state variable for controlling whether the modal is shown or not
  const [showModal, setShowModal] = useState(true);

  // Create a function to close the modal
  const handleClose = () => {
    setShowModal(false);
  };

  // Hardcoded data for all cases
  const casesData = [
    {
      id: 1,
      imgSrc: "https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2370&q=80",
      altText: "scales of justice",
      date: "April 7th, 2023",
      title: "Tobar v. Garland, No. 21-60875 (5th Cir. 2023)",
      subtitle: "Fifth Circuit Court of Appeals",
      description: "In this Fifth Circuit opinion, a native and citizen of El Salvadorian was denied Temporary Protected Status (“TPS”) due to her inability to satisfy the continuous physical presence requirement. The Petitioner had entered the U.S. without inspection in 1997 and despite a removal order, had remained. She was granted TPS in 2003 under a different name and had left the country for 111 days in 2015 to visit her sick father in El Salvador. This led to her apprehension upon return to the U.S. and initiation of removal proceedings. The Immigration Judge and Board of Immigration Appeals ruled that her absence was not 'brief, casual, and innocent' under 8 C.F.R. § 1244.1 due to its extended duration and her delayed visit to see her father. On appeal, the Fifth Circuit, exercising jurisdiction, upheld the Board’s decision, noting that the phrase 'of short duration and reasonably calculated to accomplish the purpose(s) for the absence' in the regulation must be interpreted based on the circumstances surrounding the trip.",
      linkToArg: "https://law.justia.com/cases/federal/appellate-courts/ca5/21-60875/21-60875-2023-04-07.html",
      linkToAudio: "https://www.ca5.uscourts.gov/OralArgRecordings/21/21-60875_3-6-2023.mp3"
    },
    {
      id: 2,
      imgSrc: "https://images.unsplash.com/photo-1513023001678-6927b70dc4a0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      altText: "Texas Capital Rotunda",
      date: "January 17th, 2022",
      title: "Diaz Esparza v. Garland, No. 19-60699 (5th Cir. 2022)",
      subtitle: "Fifth Circuit Court of Appeals",
      description: "The Fifth Circuit dismissed a petition for review of the Board of Immigration Appeals’ final order of removal finding that the Petitioner was subject to removal because he committed two crimes involving moral turpitude (“CIMTs”) under 8 U.S.C. § 1227(a)(2)(A)(ii). After determining that res judicata did not bar the proceedings, despite the fact that one of the CIMT convictions had been litigated in prior proceedings, the Fifth Circuit concluded that the Petitioner's conviction for Texas deadly conduct qualified as a CIMT because reckless offenses may constitute CIMTs and deadly conduct, which requires an offender to take actions creating imminent danger or serious physical injury, is categorically a CIMT. Finally, the Fifth Circuit also determined that Petitioner's 2005 adjustment of status to that of a lawful permanent resident constituted an admission for purposes of his removal proceedings. Consequently, because the Petitioner's convictions for deadly conduct and evading arrest occurred after he adjusted his status, he had been convicted of two CIMTs after admission into the United States.",
      linkToArg: "https://law.justia.com/cases/federal/appellate-courts/ca5/19-60699/19-60699-2022-01-17.html",
      linkToAudio: "https://www.ca5.uscourts.gov/OralArgRecordings/19/19-60699_4-27-2021.mp3"
    },
    {
      id: 3,
      imgSrc: "https://images.unsplash.com/photo-1582653402347-a59d799cbb0e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      altText: "Map of United States and Mexico",
      date: "July 29th, 2022",
      title: "Vazquez-Guerra v. Garland, No. 18-60828 (5th Cir. 2021)",
      subtitle: "Fifth Circuit Court of Appeals",
      description: "The Fifth Circuit dismissed a petition for review of an asylum and withholding of removal denial sought by a Mexican national and citizen, who sought relief for herself and her minor daughter, fearing that they would be persecuted by the Zetas drug cartel if returned to Mexico. This fear arose due to the lead Petitioner’s inquiries into her brother's disappearance, which she believes was at the hands of the Zetas. The Board of Immigration Appeals and the Immigration Judge denied her applications, concluding she failed to demonstrate a nexus between her alleged harm or fears and her particular social group—her immediate family. The Fifth Circuit upheld this decision, noting that the threats she received were due to her investigation into the cartel's actions, not her familial ties. The Court also noted that her other family members who remain in Mexico have not been harmed, thus further undermining her claim. Given the Fifth Circuit’s conclusion that she failed to meet the nexus threshold for asylum, the Court also found that Petitioner necessarily could not meet the heightened standard for withholding of removal.",
      linkToArg: "https://law.justia.com/cases/federal/appellate-courts/ca5/18-60828/18-60828-2021-07-29.html",
      linkToAudio: "https://www.ca5.uscourts.gov/OralArgRecordings/18/18-60828_7-9-2021.mp3"
    },
    {
      id: 4,
      imgSrc: "https://images.unsplash.com/photo-1575320181282-9afab399332c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      altText: "US Government Building",
      date: "June 4th, 2021",
      title: "Cortez-Ramirez v. Garland, No. 19-60553 (5th Cir. 2021)",
      subtitle: "Fifth Circuit Court of Appeals",
      description: "In this case, the Fifth Circuit examined the Petitioner's claims of persecution due to religious beliefs, which he argued rendered him eligible for asylum, withholding of removal, and protection under the Convention Against Torture (“CAT”). The Court ultimately found that the persecution Petitioner suffered was not primarily due to his Christian faith but was, instead, the result of his refusal to join local gangs. The Fifth Circuit upheld the Board of Immigration Appeals’ decision denying Petitioner’s claims, concluding that this experiences were at best tangentially related to a protected ground. The Court also ruled that the standard for establishing a sufficient nexus for asylum and withholding of removal was the same, countering the Petitioner's contention that a lower standard applies in the latter context. Despite acknowledging different interpretations in other circuits (currently the Sixth and Ninth Circuits), the Fifth Circuit concluded that a protected ground must be “one central reason” for the persecution suffered or feared, to establish nexus for asylum and withholding of removal. On the issue of CAT protection, the Court found that the Petitioner's claim of likely torture upon removal to El Salvador was unfounded, given the lack of sufficient evidence that Salvadoran authorities would acquiesce to such torture. The court ruled that the petitioner did not meet his burden of proof, affirming the BIA's decision, and denied the petition for review.",
      linkToArg: "https://law.justia.com/cases/federal/appellate-courts/ca5/19-60553/19-60553-2021-06-04.html",
      linkToAudio: "https://www.ca5.uscourts.gov/OralArgRecordings/19/19-60553_3-30-2021.mp3"
    },
    {
      id: 5,
      imgSrc: "https://images.unsplash.com/photo-1510265382668-7b564935d7b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80",
      altText: "US Flag",
      date: "March 15th, 2022",
      title: "Alvarenga Romero v. U.S. Attorney General, No. 20-13187 (11th Cir. 2022)",
      subtitle: "Eleventh Circuit Court of Appeals",
      description: "The U.S. Court of Appeals for the Eleventh Circuit examined the Board of Immigration Appeals’ denial of a mentally ill Petitioner’s requests for asylum, withholding of removal, and relief under the Convention Against Torture (“CAT”). The Court’s focus was on the nexus requirement, and whether Petitioner established that the central reason for his feared persecution would be on account of his social group membership, premised on his mental illness. The Eleventh Circuit agreed with the Petitioner’s argument that he would likely be targeted by gang members due to his mental illness. The Court ruled that the Board erred in finding that the Petitioner failed to establish a nexus between his membership in his particular social groups, and his feared future persecution by gangs in El Salvador, therefore vacating the denial of his asylum claim and remanding it for further proceedings.",
      linkToArg: "https://law.justia.com/cases/federal/appellate-courts/ca11/20-13187/20-13187-2022-03-15.html",
      linkToAudio: "https://www.ca11.uscourts.gov/oral-argument-recordings?title=20-13187&field_oar_case_name_value=&field_oral_argument_date_value%5Bvalue%5D%5Byear%5D=&field_oral_argument_date_value%5Bvalue%5D%5Bmonth%5D="
    },
    {
      id: 6,
      imgSrc: "https://images.unsplash.com/photo-1636652966850-5ac4d02370e9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      altText: "Government Building",
      date: "June 28th, 2022",
      title: "Singh v. Garland, No. 21-6135 (2d Cir. 2023)",
      subtitle: "Second Circuit Court of Appeals",
      description: "In this case, the Immigration Judge and Board of Immigration Appeals denied a native and citizen of India’s claims for asylum, withholding of removal, and protection under the Convention Against Torture (“CAT”), after finding that the applicant’s testimony was not credible, and that his documentary evidence did not sufficiently rehabilitate his testimony. The U.S. Court of Appeals for the Second Circuit affirmed this denial, after concluding that the Petitioner provided vague and evasive testimony throughout his individual hearing. Although the Court recognized that testimonial omissions are not as probative as contradictions, it still found that the applicant’s testimony, in the totality, was not credible. The Court further determined that his evidence of record, including three affidavits from individuals in his native India, were insufficient to independently meet his burden of proof since the affidavits were boilerplate and also lacked substantial details pertaining to the Petitioner’s claims.",
      note: "* Please note that the audio recording for this case does not have a direct link. If you wish to listen to the recording, you will need to copy the docket number and search manually.",
      linkToArg: "https://law.justia.com/cases/federal/appellate-courts/ca2/19-2910/19-2910-2023-01-19.html",
      linkToAudio: "https://www.ca2.uscourts.gov/decisions.html"
    },
    {
      id: 7,
      imgSrc: "https://images.unsplash.com/photo-1589391886645-d51941baf7fb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      altText: "Gavel",
      date: "Argument Held June 5, 2023 (Decision Forthcoming)",
      title: "Garcia-Gonzalez v. Garland, Case No. 22-60501 (5th Cir. 2023)",
      subtitle: "Fifth Circuit Court of Appeals",
      description: "The Fifth Circuit was asked to review the Board of Immigration Appeals’ denial of a motion to reopen premised on ineffective assistance of counsel. While the Board recognized that the motion complied with all of the requirements necessary for the case to be reopened, due to ineffective assistance of counsel, the Board still denied the motion after finding that the Petitioner failed to show she would be entitled to asylum in reopened proceedings. The Petitioner argued before the Fifth Circuit, that the Board applied an unduly burdensome standard to her reopening request, since an applicant in her position need only show there is a reasonable possibility she would be eligible for asylum, in reopened proceedings. A decision from the Fifth Circuit is expected shortly on this case.",
      linkToArg: "/notfound",
      linkToAudio: "https://www.ca5.uscourts.gov/OralArgRecordings/22/22-60501_6-5-2023.mp3",
    },
    // {
    //   id: 8,
    //   imgSrc: "https://images.unsplash.com/photo-1615633134784-2a88832815d1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    //   altText: "Statue of Liberty",
    //   date: "Ex. 07 Sep, 2022",
    //   title: "Ex. Case #8: Fifth Circuit Court of Appeals",
    //   subtitle: "Fifth Circuit Court of Appeals",
    //   description: "Ex. A detailed analysis of a complex citizenship application handled by Ross Miller, showcasing the strategic decisions that led to a successful outcome.",
    //   linkToArg: "/notfound",
    //   linkToAudio: "/notfound"
    // },
    // {
    //   id: 9,
    //   imgSrc: "https://images.unsplash.com/photo-1505664194779-8beaceb93744?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    //   altText: "Library Interior",
    //   date: "Ex. 07 Sep, 2022",
    //   title: "Ex. Case #9: Fifth Circuit Court of Appeals",
    //   subtitle: "Fifth Circuit Court of Appeals",
    //   description: "Ex. A detailed analysis of a complex citizenship application handled by Ross Miller, showcasing the strategic decisions that led to a successful outcome.",
    //   linkToArg: "/notfound",
    //   linkToAudio: "/notfound"
    // },
    // {
    //   id: 10,
    //   imgSrc: "https://images.unsplash.com/photo-1658958327132-a80f8a9409fb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1384&q=80",
    //   altText: "Supreme Court Building",
    //   date: "Ex. 07 Sep, 2022",
    //   title: "Ex. Case #10: Fifth Circuit Court of Appeals",
    //   subtitle: "Fifth Circuit Court of Appeals",
    //   description: "Ex. A detailed analysis of a complex citizenship application handled by Ross Miller, showcasing the strategic decisions that led to a successful outcome.",
    //   linkToArg: "/notfound",
    //   linkToAudio: "/notfound"
    // }
    // More cases here...
  ];

  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/cases/*" element={<Cases casesData={casesData} />}>
              <Route path=":id" element={<SingleCaseView casesData={casesData} />} />
            </Route>
            <Route path="/notfound" element={<NotAvailable casesData={casesData} />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />

        {/* Temporary Modal while under construction */}
        <Modal show={showModal} onClose={handleClose}>
          Please be aware that this website is currently in a development phase and is under construction. As such, the information and statistics present are not updated and are used exclusively for development. We are not responsible for the content's accuracy during this period.
        </Modal>
      </div>
    </Router>
  );
}

export default App;
