import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/ross.miller.esq.logo.png';

function Navbar() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('home');
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const currentPath = location.pathname.split('/')[1] || 'home';
        setActiveMenuItem(currentPath);
    }, [location]);


    const handleToggle = () => {
        setIsActive(!isActive);
        setIsExpanded(!isExpanded);
        document.body.classList.toggle('cs-open');
    };

    const closeMenu = () => {
        setIsExpanded(false);
        setIsActive(false);
        document.body.classList.remove('cs-open');
    };

    return (
        <>
            <header id="cs-navigation" className={isActive ? 'cs-active' : ''}>
                <div className="cs-container">
                    <Link to="/" className="cs-logo" aria-label="back to home" onClick={closeMenu}>
                        <img
                            src={logo}
                            alt="logo"
                            width="210"
                            height="29"
                            aria-hidden="true"
                            decoding="async"
                        />
                    </Link>
                    <nav className="cs-nav" role="navigation">
                        <button
                            className={`cs-toggle ${isActive ? 'cs-active' : ''}`}
                            aria-label="mobile menu toggle"
                            onClick={handleToggle}
                            aria-expanded={isExpanded}
                        >
                            <div className="cs-box" aria-hidden="true">
                                <span className="cs-line cs-line1" aria-hidden="true"></span>
                                <span className="cs-line cs-line2" aria-hidden="true"></span>
                                <span className="cs-line cs-line3" aria-hidden="true"></span>
                            </div>
                        </button>
                        <div className="cs-ul-wrapper">
                            <ul
                                id="cs-expanded"
                                className={`cs-ul ${isExpanded ? 'cs-active' : ''}`}
                            >
                                <li className="cs-li">
                                    <Link to="/" className={`cs-li-link ${activeMenuItem === 'home' ? 'cs-active' : ''}`} onClick={closeMenu}>
                                        Home
                                    </Link>
                                </li>
                                <li className="cs-li">
                                    <Link to="/about" className={`cs-li-link ${activeMenuItem === 'about' ? 'cs-active' : ''}`} onClick={closeMenu}>
                                        About
                                    </Link>
                                </li>
                                <li className="cs-li">
                                    <Link to="/services" className={`cs-li-link ${activeMenuItem === 'services' ? 'cs-active' : ''}`} onClick={closeMenu}>
                                        Services
                                    </Link>
                                </li>
                                <li className="cs-li">
                                    <Link to="/cases" className={`cs-li-link ${activeMenuItem === 'cases' ? 'cs-active' : ''}`} onClick={closeMenu}>
                                        Cases
                                    </Link>
                                </li>
                                <li className="cs-li">
                                    <Link to="/contact" className={`cs-li-link cs-button-solid cs-nav-button ${activeMenuItem === 'contact' ? 'cs-active' : ''}`} onClick={closeMenu}>
                                        Get In Touch
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Navbar;
