import React, { useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import SingleCaseView from '../SingleCaseView/SingleCaseView';
import './Cases.css';

function createPreview(description) {
    let words = description.split(" ");
    if (words.length > 25) {
        return words.slice(0, 25).join(" ") + "...";
    } else {
        return description;
    }
}


function Cases({ casesData }) {
    const [showAllCases, setShowAllCases] = useState(false);

    // Featured case
    const featuredCase = casesData[0];

    // All other cases (from the second one)
    const otherCases = casesData.slice(1);

    // Cases to show (exclude the featured case)
    const casesToShow = showAllCases ? otherCases : otherCases.slice(0, 2);

    const isEven = casesToShow.length % 2 === 0;

    const handleClick = () => {
        setShowAllCases(true);
    }

    return (
        <Routes>
            <Route index element={
                <>
                    <section id="blog-845">
                        <div className="cs-container">
                            <div className="cs-content">
                                <span className="cs-topper">Case Studies</span>
                                <h2 className="cs-title">Significant Cases by Ross Miller, Esq.</h2>
                            </div>
                            <ul className="cs-card-group">
                                {/* Featured Case */}
                                <li className="cs-item cs-featured">
                                    <picture className="cs-picture">
                                        <source media="(max-width: 600px)" srcSet={featuredCase.imgSrc} />
                                        <source media="(min-width: 601px)" srcSet={featuredCase.imgSrc} />
                                        <img loading="lazy" decoding="async" src={featuredCase.imgSrc} alt={featuredCase.altText} width="369" height="290" />
                                    </picture>
                                    <div className="cs-item-content">
                                        <span className="cs-date">{featuredCase.date}</span>
                                        <h3 className="cs-h3">{featuredCase.title}</h3>
                                        <p className="cs-item-text">{createPreview(featuredCase.description)}</p>
                                        <Link to={`/cases/${featuredCase.id}`} className="cs-link">
                                            Read More
                                            <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Forange-carrot-right.svg" alt="arrow right" width="20" height="20" loading="lazy" decoding="async" aria-hidden="true" />
                                        </Link>
                                    </div>
                                </li>

                                {/* Other Cases */}
                                {casesToShow.map((caseData, index) => {
                                    const isLastItem = index === casesToShow.length - 1;
                                    const classes = "cs-item" + (showAllCases ? " expand-grid" : "") + (isLastItem && isEven ? " full-width" : "");

                                    return (
                                        <li key={caseData.id} className={classes}>
                                            <picture className="cs-picture">
                                                <source media="(max-width: 600px)" srcSet={caseData.imgSrc} />
                                                <source media="(min-width: 601px)" srcSet={caseData.imgSrc} />
                                                <img loading="lazy" decoding="async" src={caseData.imgSrc} alt={caseData.altText} width="369" height="290" />
                                            </picture>
                                            <div className="cs-item-content">
                                                <span className="cs-date">{caseData.date}</span>
                                                <h3 className="cs-h3">{caseData.title}</h3>
                                                <p className="cs-item-text">{createPreview(caseData.description)}</p>
                                                <Link to={`/cases/${caseData.id}`} className="cs-link">
                                                    Read More
                                                    <img className="cs-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Forange-carrot-right.svg" alt="arrow right" width="20" height="20" loading="lazy" decoding="async" aria-hidden="true" />
                                                </Link>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            {!showAllCases && <button className="cs-button-solid" onClick={handleClick}>View All Cases</button>}
                        </div>
                    </section>

                </>
            } />
            <Route path=":id" element={<SingleCaseView casesData={casesData} />} />
        </Routes>
    )
}

export default Cases;
