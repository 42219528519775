import React from 'react';
import { Link } from 'react-router-dom';

import './About.css';
import statueOfLiberty from '../../assets/images/statue-of-liberty.webp';
// import Ross2 from '../../assets/images/Ross21.webp';


function About() {

    const Ross2 = 'https://ik.imagekit.io/s3i7pk3bg/Ross2.webp?updatedAt=1688407285456';
    return (
        <>
            <section id="sbsr-312">
                <div className="cs-container">
                    <div className="cs-image-group">
                        <picture className="cs-picture cs-picture1">
                            <source media="(max-width: 600px)" srcSet={statueOfLiberty} />
                            <source media="(min-width: 601px)" srcSet={statueOfLiberty} />
                            <img loading="lazy" decoding="async" srcSet={statueOfLiberty} alt="Statue of Liberty" width="522" height="581" aria-hidden="true" />
                        </picture>
                        <picture className="cs-picture cs-picture2">
                            <source media="(max-width: 600px)" srcSet={Ross2} />
                            <source media="(min-width: 601px)" srcSet={Ross2} />
                            <img loading="lazy" decoding="async" srcSet={Ross2} alt="Photo of Ross" width="414" height="400" aria-hidden="true" />
                        </picture>
                    </div>
                    <div className="cs-content">
                        <span className="cs-topper">About</span>
                        <h2 className="cs-title">Ross Miller, Esq.</h2>
                        <div className="cs-text">
                            <p className='cs-paragraph'>
                                Ross Miller is a shareholder and head of the appeals department at Gonzalez Olivieri LLC immigration firm in Houston, Texas. He has argued cases before the U.S. Courts of Appeal for the Second, Fifth, and Eleventh Circuits. He currently serves on the State Bar of Texas’ Immigration and Nationality Section council, and as a New Member Division Liaison for the Texas Chapter of the American Immigration Lawyers Association (“AILA”). Ross has been a guest speaker on immigration matters for the State Bar of Texas and for AILA. Since becoming an attorney in 2018, he has been named a Texas Rising Star by Super Lawyers in 2021, 2022, and 2023.
                            </p>
                            <p className='cs-paragraph'>
                                He holds a bachelor’s degree from St. Lawrence University in Canton, New York and a master’s degree from Clarkson University in Potsdam, New York. Ross graduated from South Texas College of Law – Houston in 2018, where he obtained his juris doctorate degree. During his time in law school, he served as a Marshall-Brennan Constitutional Literacy Fellow, and was a varsity advocate on the nationally-renowned South Texas Moot Court team. As an award-winning advocate, he competed in national moot court tournaments throughout the United States, where he won top awards for his appellate advocacy skills. Ross has put these superior legal skills to good use at Gonzalez Olivieri, where he works to ensure that the firm’s clients have their fair day in court.
                            </p>

                        </div>
                        {/* <div className="cs-quote">
                            <img className="cs-quote" id='quote-margin-bottom-0' aria-hidden="true" src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/quotes-white.svg" decoding="async" alt="quote icon" width="40" height="33" />
                            <span className="cs-quote-text">
                                This quote will be something nice Raed has said about Ross and how his journey at the firm.
                            </span>
                            <span className="cs-name">Raed Gonzalez</span>
                            <span className="cs-job">JD, LLM, Chairman</span>
                            <img className="cs-quote-icon" loading="lazy" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/SideBySide/quote-white.svg" alt="gavel" width="136" height="77" />
                        </div> */}
                        {/* <span className="cs-topper">About</span>
                        <h2 className="cs-title">Gonzalez Olivieri, LLC.</h2>
                        <div className="cs-text">
                            <p className='cs-paragraph'>
                                The Houston law firm Gonzalez Olivieri LLC is one of the new Texas leading immigration law firms. Immigration is not just a practice area for us, it is all we do.  Led by founder and senior attorney Raed Gonzalez, JD, LLM, the firm, associates and staff bring more than 30 years of legal experience and expertise to your unique immigration case.  Raed Gonzalez is a nationally recognized litigation attorney with published precedential decisions with the Supreme Court of the US and the 5th Circuit Court of Appeals.  The firm practices removal defense, family-based immigration, litigation and appeals, naturalization and U.S. citizenship claims, as well as representing corporate America with all types of nonimmigrant visas including investors, H1B, H2B, L1, E, TN, O, P, foreign professionals, multinational managers, athletes and individuals with extraordinary abilities. Our knowledge of international legal, regulatory and policy issues allows us to provide our clients with strategic advice and effective and efficient immigration solutions to assist them in achieving and maintaining a competitive edge in the global marketplace.
                            </p>
                            <strong className='cs-topper'>
                                Gonzalez Olivieri LLC was the official immigration adviser to the Harris County Public Defender’s Office.
                            </strong>
                        </div>
                        <a href="https://www.gonzalezolivierillc.com/" className="cs-button-solid">Our Law Firm</a> */}
                    </div>
                </div>
            </section>
            <section id="stats-5">
                <div className="cs-container">
                    <div className="cs-content">
                        <span className="cs-topper cs-topper-white">Why Work With Ross</span>
                        <span className="cs-topper cs-topper-white">at Gonzalez Olivieri, LLC</span>
                        <h2 className="cs-title">Experience Evident in Numbers</h2>
                        <p className="cs-text">
                        As a key part of Houston's top-rated immigration law firm, Ross Miller, a dedicated shareholder and head of Appeals, stands as your dependable guide in navigating your immigration journey. Under the leadership of senior attorney Raed Gonzalez, the firm leverages over 30 years of legal expertise to deliver strategic advice and effective solutions in immigration law.
                        </p>
                    </div>
                    <ul className="cs-card-group">
                        <li className="cs-item">
                            <span className="cs-number">30+</span>
                            <span className="cs-header">Years of Legal Experience and Expertise</span>
                        </li>
                        <li className="cs-item">
                            <span className="cs-number">13</span>
                            <span className="cs-header">Supreme Court Wins</span>
                        </li>
                        <li className="cs-item">
                            <span className="cs-number">16</span>
                            <span className="cs-header">Experienced Immigration Attorneys</span>
                        </li>
                        <li className="cs-item">
                            <span className="cs-number">8</span>
                            <span className="cs-header">Practice Areas</span>
                        </li>
                    </ul>
                    <Link to="/contact" className="cs-button-solid">Contact Us</Link>
                </div>
                <picture className="cs-background">
                    <source media="(max-width: 600px)" srcSet="https://images.unsplash.com/photo-1498262257252-c282316270bc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" />
                    <source media="(min-width: 601px)" srcSet="https://images.unsplash.com/photo-1498262257252-c282316270bc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" />
                    <img loading="lazy" decoding="async" src="https://images.unsplash.com/photo-1498262257252-c282316270bc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" alt="brick wall" width="1920" height="1282" aria-hidden="true" />
                </picture>
            </section>
        </>
    )
}

export default About;
