import React from 'react';
import './Contact.css';
import { useForm, ValidationError } from '@formspree/react';

function Contact() {
    const [state, handleSubmit] = useForm("mrgwzjdy");

    return (
        <>
            <section id="cs-contact-265">
                <div className="cs-container">
                    <div className="cs-left-section">
                        <div className="cs-content">
                            <span className="cs-topper">Contact</span>
                            <h2 className="cs-title">Get in Touch</h2>
                            <p className="cs-text">
                                Don't hesitate to reach out to us for any questions or issues regarding your immigration journey. Ross Miller and the entire team at Gonzalez Olivieri LLC are committed to providing you with tailored advice and representation to suit your unique needs.
                            </p>
                        </div>
                        {state.succeeded ? (
                            <div className="success-message">
                                <h3>Thanks for your submission!</h3>
                                <p className="cs-text">We will get back to you in 24 to 48 hours. If you need immediate assistance, please call our office at (111) 222-3333. Our normal business hours are 00am to 00pm.</p>
                            </div>
                        ) : (
                            <form id="cs-form-265" name="Contact Form" method="post" onSubmit={handleSubmit}>
                                <label>
                                    Name
                                    <input required type="text" id="name-265" name="name" placeholder="First Name + Last Name" />
                                    <ValidationError prefix="Name" field="name" errors={state.errors} />
                                </label>
                                <label>
                                    Email
                                    <input required type="email" id="email-265" name="email" placeholder="Email Address" />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                                </label>
                                <label>
                                    Phone
                                    <input required type="text" id="phone-265" name="phone" placeholder="Phone Number" />
                                    <ValidationError prefix="Phone" field="phone" errors={state.errors} />
                                </label>
                                <label>
                                    How Did You Find Us
                                    <select id="find-265" name="find-us" required>
                                        <option value="">Please choose an option</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Word of Mouth">Word of Mouth</option>
                                        <option value="Advertisement">Advertisement</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="News Article">News Article</option>
                                        <option value="TV/Radio">TV/Radio</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <ValidationError prefix="How Did You Find Us" field="find-us" errors={state.errors} />
                                </label>

                                <label className="cs-label-message">
                                    Message
                                    <textarea required name="message" id="message-265" placeholder="Write message..."></textarea>
                                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                                </label>
                                <button className="cs-button-solid cs-submit" type="submit" disabled={state.submitting}>Submit Message</button>
                            </form>
                        )}
                    </div>
                    <div className="cs-right-section">
                        <span className="cs-header">Email</span>
                        <a className="cs-link" href="mailto:rmiller@gonzalezolivierillc.com">rmiller@gonzalezolivierillc.com</a>
                        <span className="cs-header">Phone</span>
                        <a className="cs-link" href="tel:713-481-3040">(713) 481-3040</a>
                        <span className="cs-header">Address</span>
                        <a className="cs-link" href="https://maps.app.goo.gl/9dvBEvPGVGJcyiMLA?g_st=ic">
                            9920 Gulf Fwy, <span className="cs-block">Houston, Texas</span>
                        </a>
                        <picture className="cs-bg-picture">
                            <source media="(max-width: 600px)" srcSet="https://images.unsplash.com/photo-1585074922416-50c24e04eb9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1635&q=80" />
                            <source media="(min-width: 601px)" srcSet="https://images.unsplash.com/photo-1585074922416-50c24e04eb9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1635&q=80" />
                            <img loading="lazy" decoding="async" src="https://images.unsplash.com/photo-1585074922416-50c24e04eb9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1635&q=80" alt="world globe" width="542" height="662" aria-hidden="true" />
                        </picture>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
